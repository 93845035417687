/* src/NavBar.css */
.custom-navbar {
  font-size: 0.9rem; /* Smaller font size */
}

.custom-brand {
  font-size: 1.1rem; /* Slightly larger brand font size */
}

.custom-link {
  font-size: 0.9rem; /* Smaller font size for links */
  padding-right: 15px; /* Spacing between links */
}

.custom-signout {
  font-size: 0.9rem; /* Smaller font size for sign-out button */
  margin-left: 10px; /* Margin to separate from links */
}
